import React, { useEffect } from "react";
import { FormButton, FormCategory, FormTitle } from "../index";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    makeStyles,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    selectLabel: {
        backgroundColor: "white",
        padding: "0 4px",
    },
    deleteButton: {
        width: "100px",
        marginLeft: "auto",
    },
    separator: {
        height: "1px",
        width: "100% !important",
        backgroundColor: theme.palette.grey[300],
        margin: "10px 0px",
    },
    halfWidth: { width: "50%" },
}));

const AdditionalContact = ({ contacts = [], setContacts, errors, readOnly = false, hasTitle = true }) => {
    const classes = useStyles();

    const addContacts = () => {
        if (contacts.length >= 8) {
            alert("Maximum number of additional contacts is 8.");
            return;
        }
        setContacts([
            ...contacts,
            {
                name: "",
                email: "",
                department: "sales",
                receiveOrderEmail: false,
            },
        ]);
    };

    const removeContacts = (index) => {
        const newContacts = contacts.filter((_, i) => index !== i);
        setContacts(newContacts);
    };

    const handleChange = (event) => {
        const [index, field] = event.target.name.split("-");
        if (field !== "receiveOrderEmail") {
            contacts[index][field] = event.target.value;
        } else {
            contacts[index][field] = event.target.checked;
        }
        setContacts(contacts);
    };

    if (readOnly && contacts.length === 0) return null;

    return (
        <FormCategory>
            {hasTitle && <FormTitle>Additional Contacts</FormTitle>}
            {contacts.map((contact, index) => (
                <>
                    {index > 0 && <div className={classes.separator}></div>}
                    <TextField
                        name={`${index}-name`}
                        variant="outlined"
                        label="Contact Name"
                        value={contact.name}
                        onChange={handleChange}
                        className={classes.halfWidth}
                        disabled={readOnly}
                    />
                    <FormControl variant="outlined" className={classes.halfWidth}>
                        <InputLabel id="department-label" className={classes.selectLabel}>
                            Department
                        </InputLabel>
                        <Select
                            labelId="department-label"
                            name={`${index}-department`}
                            value={contact.department}
                            onChange={handleChange}
                            disabled={readOnly}
                        >
                            <MenuItem value="sales">Sales</MenuItem>
                            <MenuItem value="management">Top Management</MenuItem>
                            <MenuItem value="marketing">Marketing</MenuItem>
                            <MenuItem value="operation">Warehouse and Operation</MenuItem>
                            <MenuItem value="finance">Finance and Accounting</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        name={`${index}-email`}
                        type="email"
                        variant="outlined"
                        label="Email"
                        value={contact.email}
                        onChange={handleChange}
                        className={classes.halfWidth}
                        disabled={readOnly}
                    />
                    <FormControlLabel
                        label="Receive Order Email"
                        control={
                            <Checkbox
                                name={`${index}-receiveOrderEmail`}
                                checked={contact.receiveOrderEmail}
                                color="primary"
                                onChange={handleChange}
                                disabled={readOnly}
                            />
                        }
                    />
                    {!readOnly && (
                        <FormButton
                            cvariant="outlined"
                            ccolor="red"
                            onClick={() => removeContacts(index)}
                            className={classes.deleteButton}
                        >
                            Remove
                        </FormButton>
                    )}
                </>
            ))}
            {!readOnly && <FormButton onClick={addContacts}>Add Additional Contact</FormButton>}
        </FormCategory>
    );
};

export default AdditionalContact;
