import { DEFAULT_ADMIN_MENU } from "constants/menu";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default () => {
    const { isAdmin, permissions } = useSelector((state) => state.profile);
    const history = useHistory();

    useEffect(() => {
        if (isAdmin) {
            const firstAuthorizedMenu = DEFAULT_ADMIN_MENU.find((m) => permissions.includes(m.permissionCode));
            if (firstAuthorizedMenu) {
                history.push(firstAuthorizedMenu.path);
            }
        } else {
            history.push("/products/list");
        }
    }, []);

    return <p style={{ fontSize: 18, margin: 16, textAlign: "center" }}>No menu permission available</p>;
};
