import { styled } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "service/actions/site";
import { DEFAULT_ADMIN_MENU, DEFAULT_MERCHANT_MENU } from "constants/menu";
import YHMenu from "./Menu";
import { useState, useEffect, useRef } from "react";

const config = {
    expand: "286px",
    collapse: "86px",
};

const YHSidebar = styled("nav")(({ theme, collapse }) => ({
    backgroundColor: theme.yoho.color.white,
    width: collapse === "true" ? config.collapse : config.expand,
    top: 64,
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 1000,
    border: `1px solid ${theme.yoho.md_color.grey[200]}`,
    transition: "0.3s width ease",
    boxShadow: "inset -2px -2px 5px rgba(0, 0, 0, 0.1)",
}));

const SidebarActionWrapper = styled("div")({
    width: "100%",
});

const SidebarAction = styled("div")({
    width: "28px",
    height: "28px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    transform: "rotate(-180deg)",
    position: "relative",
    background: "white",
    right: "-10px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    right: "-14px",
    zIndex: "5000",
    cursor: "pointer",
    position: "absolute",
    top: "66px",
});

export default () => {
    const dispatch = useDispatch();
    const drawerCollpase = useSelector((state) => state.site.drawer);
    const [activeMenu, setActiveMenu] = useState(null);
    const sideBarRef = useRef(null);
    const { isAdmin, permissions } = useSelector((state) => state.profile);
    const menuConfig = isAdmin
        ? DEFAULT_ADMIN_MENU.filter(({ permissionCode }) => permissions.includes(permissionCode))
        : DEFAULT_MERCHANT_MENU;

    const openDrawer = (open) => dispatch(setDrawer(!open));

    useEffect(() => {
        drawerCollpase && setActiveMenu(null);
    }, [drawerCollpase]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // SVG can somehow evade the include check
            const notSvg = event.target.tagName !== "svg";
            if (!sideBarRef.current.contains(event.target) && notSvg) {
                setActiveMenu(null);
                openDrawer(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, []);

    return (
        <YHSidebar collapse={drawerCollpase.toString()} ref={sideBarRef}>
            <SidebarActionWrapper>
                <SidebarAction
                    onClick={(event) => {
                        event.stopPropagation();
                        dispatch(setDrawer(!drawerCollpase));
                    }}
                >
                    {drawerCollpase ? <ChevronLeft /> : <ChevronRight />}
                </SidebarAction>
            </SidebarActionWrapper>

            {menuConfig.map((item, idx) => (
                <YHMenu
                    menu={item}
                    key={idx}
                    index={idx}
                    collapse={drawerCollpase}
                    openDrawer={openDrawer}
                    active={activeMenu}
                    setActive={setActiveMenu}
                />
            ))}
        </YHSidebar>
    );
};
