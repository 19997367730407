import AuthContainer from "components/AuthPage";
import AdminContainer from "components/Layout";
import cookie from "js-cookie";
import PageNotFound from "pages/error-module/PageNotFound";
import private_routes from "pages/_routers/private";
import public_routes from "pages/_routers/public";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { initAPP, setRedirect } from "service/actions/site";
import api from "service/apis/config";

function jwtNotExpired(token) {
    try {
        const tokenParts = token.split(".");
        if (tokenParts.length !== 3) throw new Error("Invalid token format");

        const payloadJson = Buffer.from(tokenParts[1], "base64").toString("utf-8");
        const payload = JSON.parse(payloadJson);

        if (payload.exp && typeof payload.exp === "number") {
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);
            return currentTimeInSeconds < payload.exp;
        } else {
            return true;
        }
    } catch (error) {
        console.error("Error while checking token expiration:", error);
        return false;
    }
}

const PublicRouting = (route) => {
    const render = ({ location }) => {
        return jwtNotExpired(cookie.get("admin-token")) ? (
            <Redirect
                to={{
                    pathname: "/admin/application",
                    state: { from: location },
                }}
            />
        ) : jwtNotExpired(cookie.get("merchant-token")) ? (
            <Redirect
                to={{
                    pathname: "/products/list",
                    state: { from: location },
                }}
            />
        ) : route.path === "/auth/newmerchant" || !route.path.includes("/auth") ? (
            <route.component />
        ) : (
            <AuthContainer>
                <route.component />
            </AuthContainer>
        );
    };
    return <Route exact={route.exact} path={route.path} render={render} />;
};

const PrivateRouting = (route) => {
    const isAdmin = useSelector((state) => state.profile.isAdmin);
    const token = cookie.get("merchant-token") || cookie.get("admin-token");

    if (token) {
        api.setHeader("Authorization", `Bearer ${token}`);
    }

    const render = ({ location }) => {
        if (token) {
            if (isAdmin || (!isAdmin && cookie.get("merchant-token"))) {
                return (
                    <AdminContainer>
                        <route.component />
                    </AdminContainer>
                );
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: `/auth/${route?.path?.includes("admin") ? "admin-login" : "login"}`,
                            state: { from: location },
                        }}
                    />
                );
            }
        } else {
            return (
                <Redirect
                    to={{
                        pathname: `/auth/${route?.path?.includes("admin") ? "admin-login" : "login"}`,
                        state: { from: location },
                    }}
                />
            );
        }
    };
    return <Route exact={route.exact} path={route.path} render={render} />;
};

export default () => {
    const redirectTo = useSelector((state) => state.site.redirect);
    const history = useHistory();
    const persist = useSelector((state) => state.site._persist.rehydrated);
    const dispatch = useDispatch();

    useEffect(() => {
        if (redirectTo === "forceAppBack") {
            history.goBack();
            dispatch(setRedirect(null));
        } else if (redirectTo !== null) {
            history.push(redirectTo);
            dispatch(setRedirect(null));
        }
    }, [redirectTo]);

    useEffect(() => {
        if (!persist) {
            dispatch(initAPP());
        }
    }, [persist]);

    return (
        <Switch>
            {public_routes.map((route, i) => (
                <PublicRouting {...route} key={i} />
            ))}
            {private_routes
                .filter((route) => route.allow)
                .map((route, i) => (
                    <PrivateRouting {...route} key={i} />
                ))}
            <Route path="*" component={PageNotFound} />
        </Switch>
    );
};
