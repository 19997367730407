import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/styles";
import { ButtonLink } from "components/Global/Form";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getLoginData } from "service/actions/authentication";

const useStyles = makeStyles((theme) => ({
    error: {
        color: "red",
    },
    title: {
        fontSize: 17,
        marginTop: 0,
        "&:before": {
            content: "",
            width: 20,
            height: 20,
            backgroundColor: "red",
        },
    },
}));

const FormTitle = styled("p")(({ theme }) => ({
    "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        top: 32,
        width: 10,
        height: 26,
        backgroundColor: theme.yoho.color.blue,
    },
    fontSize: 28,
    fontWeight: 500,
    lineHeight: "36px",
    marginTop: 0,
    color: theme.yoho.color.blue,
}));

const ForgotPasswordContainer = styled("div")(({ theme }) => ({
    display: "flex",
    gap: "10px",
    "& > p": {
        cursor: "pointer",
        margin: 0,
        "&:hover": {
            color: theme.yoho.color.blue,
        },
    },
}));

const FormButton = styled(Button)(({ theme }) => ({
    height: "52px",
    ...theme.yoho.button.contained.blue,
    margin: "10px 0",
    textTransform: "capitalize",
}));

const RegisterContainer = styled("div")(({ theme }) => ({
    marginTop: "6px",
    display: "flex",
    justifyContent: "center",
    ...theme.yoho.typography.subtitle1,
}));

const ForgetPasswordText = styled(Link)(({ theme }) => ({
    ...theme.yoho.typography.subtitle2,
    color: theme.yoho.md_color.grey[800],
    textDecoration: "none",
}));

export default () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const location = useLocation();
    const loginType = location.pathname.split("/").at(-1);

    const onSubmit = ({ username, password }) => {
        dispatch(getLoginData({ username, password, type: loginType }));
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <FormTitle className={classes.title}>
                {loginType === "admin-login" ? "Admin Login" : "Merchant Login"}
            </FormTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={0}>
                    <Grid item sm={12} xs={12} style={{ marginBottom: "10px" }}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            name="username"
                            inputRef={register({ required: true })}
                        />
                        {errors.username && <span className={classes.error}>This field is required</span>}
                    </Grid>
                    <Grid item sm={12} xs={12} style={{ marginBottom: "32px" }}>
                        <FormControl style={{ width: "100%", margin: "10px 0" }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                inputRef={register({ required: true })}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            style={{ width: 40, height: 40 }}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            <img
                                                src={
                                                    showPassword
                                                        ? "/assets/icon/MaskOn.svg"
                                                        : "/assets/icon/MaskOff.svg"
                                                }
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        {errors.password && <span className={classes.error}>This field is required</span>}
                        {loginType !== "admin-login" && (
                            <ForgotPasswordContainer>
                                <img src="/assets/icon/ForgotPass.svg" />
                                <ForgetPasswordText to="/auth/forgotpass">Forget password</ForgetPasswordText>
                            </ForgotPasswordContainer>
                        )}
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <FormButton variant="contained" type="submit" fullWidth>
                            Continue
                        </FormButton>
                        {loginType !== "admin-login" && (
                            <RegisterContainer>
                                Become our merchant.
                                <ButtonLink
                                    to="/auth/newmerchant"
                                    cvariant="text"
                                    ccolor="blue"
                                    style={{ marginLeft: 3 }}
                                >
                                    Sign up
                                </ButtonLink>
                                !
                            </RegisterContainer>
                        )}
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
