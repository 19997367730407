import { Checkbox, FormControlLabel, styled, TextField } from "@material-ui/core";
import { FormButton, FormCategory, YohoIcon, YohoSelect } from "components/Global";
import { FormText } from "components/Global/Form";
import AdditionalContact from "components/Global/Form/AdditionalContact";
import InputText from "components/Global/Form/InputText";
import { TextSelection } from "components/Global/Form/NormalSelect";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateStoreContact } from "service/actions/merchant";
import { setSnackbar } from "service/actions/site";

const Section = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 20,
    "& > *": {
        width: "100%",
        "&.row": {
            display: "flex",
            "& > div": {
                display: "flex",
                "&.label": {
                    display: "flex",
                    alignItems: "center",
                    minWidth: 200,
                },
                "&.field": {
                    display: "flex",
                    gap: 16,
                    width: "100%",
                    "& > div": {
                        width: "100%",
                    },
                },
            },
        },
    },
    "&.office": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *": {
            width: "max-content",
            "&:first-child": {
                minWidth: 180,
            },
        },
        "& .hours-diff": {
            display: "flex",
            alignItems: "center",
            gap: "10px",
        },
        "& .office_custom": {
            display: "flex",
            gap: 10,
            flexDirection: "column",
            "& .office_day": {
                display: "flex",
                alignItems: "center",
                "& > *": {
                    "&:first-child": {
                        minWidth: 180,
                    },
                },
            },
        },
    },
});

const Divider = styled("div")(({ theme }) => ({
    borderBottom: `1px solid ${theme.yoho.md_color.grey[300]}`,
    margin: "30px 0 10px 0",
    width: "100% !important",
}));

const productOptions = [
    { name: "Every day", value: "every" },
    { name: "Weekday", value: "week" },
    { name: "Custom", value: "custom" },
];

const after_sales = [
    { name: "Replace missing or damaged parts" },
    { name: "Troubleshoot technical issues" },
    { name: "Parcel is not yet received" },
];

const office_custom = [
    { name: "Monday", value: "monday" },
    { name: "Tuesday", value: "tuesday" },
    { name: "Wednesday", value: "wednesday" },
    { name: "Thursday", value: "thursday" },
    { name: "Friday", value: "friday" },
    { name: "Saturday", value: "saturday" },
    { name: "Sunday", value: "sunday" },
];

export default () => {
    const dispatch = useDispatch();
    const contacts = useSelector((state) => state.merchant.setting.store?.contacts);
    const [ready, setReady] = useState(false);
    const [cOffice, setCOffice] = useState(contacts?.office_day);
    const internationalcode = useSelector((state) => state.site.config_internationalcode);

    const { register, handleSubmit, watch, getValues, control, reset, setValue } = useForm({ shouldUnregister: false });

    useEffect(() => {
        if (internationalcode && contacts) {
            reset(contacts);
            setReady(true);
        }
    }, [contacts, internationalcode]);

    const onSubmit = (data) => {
        if (data.hasOwnProperty("whatsapp_international_code")) {
            data.whatsapp_international_code = data.whatsapp_international_code?.dial_code;
        }
        if (data.hasOwnProperty("hotline_international_code")) {
            data.hotline_international_code = data.hotline_international_code?.dial_code;
        }

        dispatch(updateStoreContact(data));
    };

    const handleCheck = (value) => {
        const { after_sales_support } = getValues();
        const afterSales = after_sales_support?.includes(value)
            ? after_sales_support?.filter((id) => id !== value)
            : [...(after_sales_support ?? []), value];
        return afterSales;
    };

    const handleOffice = (e) => {
        setCOffice(e.target.value);
    };

    const handleCustomOffice = (data, args) => {
        const { office_time_custom } = getValues();

        let current = [...office_time_custom];
        let getIndex = current.findIndex((item) => item.day_name === args);
        let getcurrentdata = current.find((item) => item.day_name === args);
        if (getcurrentdata) {
            let valdata = {
                ...getcurrentdata,
                [data.target.name]: data.target.value,
            };
            current[getIndex] = valdata;
        } else {
            let valdata = { day_name: args, [data.target.name]: data.target.value };
            current.push(valdata);
        }
        return current;
    };

    return (
        ready && (
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormCategory>
                    <FormText>
                        <p className="subtitle1">After-sales support to the following reason(s)</p>
                    </FormText>
                    <Section className="full">
                        <Controller
                            name="after_sales_support"
                            render={(props) =>
                                after_sales.map((item, index) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onChange={() => props.onChange(handleCheck(item.name))}
                                                defaultChecked={contacts?.after_sales_support?.includes(item.name)}
                                            />
                                        }
                                        key={item.name}
                                        label={item.name}
                                    />
                                ))
                            }
                            control={control}
                        />
                        <InputText
                            control={control}
                            name="other_after_sales_support"
                            variant="outlined"
                            label="Other Reason"
                        />
                    </Section>
                    <Divider />
                    <Section className="full">
                        <FormText>
                            <p className="subtitle1">After-sales Contact</p>
                        </FormText>
                        <div className="row">
                            <div className="label">
                                <YohoIcon src="/assets/icon/whatsapp.svg" size={4} />
                                <FormText>
                                    <p className="subtitle1">WhatsApp</p>
                                </FormText>
                            </div>
                            <div className="field">
                                <YohoSelect
                                    inputRef={register({ required: true })}
                                    control={control}
                                    name="whatsapp_international_code"
                                    className="w-3"
                                    data={internationalcode}
                                    label="International Codes"
                                    show={["dial_code", "name"]}
                                />
                                <InputText
                                    control={control}
                                    name="whatsapp"
                                    variant="outlined"
                                    label="Whatsapp Number"
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">
                                <YohoIcon src="/assets/icon/phone.svg" size={4} />
                                <FormText>
                                    <p className="subtitle1">Hotline</p>
                                </FormText>
                            </div>
                            <div className="field">
                                <YohoSelect
                                    inputRef={register({ required: true })}
                                    control={control}
                                    name="hotline_international_code"
                                    className="w-3"
                                    data={internationalcode}
                                    label="International Codes"
                                    show={["dial_code", "name"]}
                                />
                                <InputText
                                    control={control}
                                    name="hotline"
                                    variant="outlined"
                                    label="Hotline Number"
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">
                                <YohoIcon src="/assets/icon/mail.svg" size={4} />
                                <FormText>
                                    <p className="subtitle1">Email</p>
                                </FormText>
                            </div>
                            <div className="field">
                                <InputText
                                    control={control}
                                    name="aftersales_email"
                                    variant="outlined"
                                    label="After-sales Email"
                                />
                            </div>
                        </div>
                    </Section>
                    <Divider />
                    <Section className="full">
                        <FormText>
                            <p className="subtitle1">Additional Contacts</p>
                        </FormText>
                        <AdditionalContact
                            hasTitle={false}
                            contacts={watch("additional_contacts")}
                            setContacts={(contacts) => setValue("additional_contacts", contacts)}
                        />
                    </Section>
                    <Divider />
                    <Section className="full office">
                        <FormText>
                            <p className="subtitle1">Office Hours</p>
                        </FormText>
                        <TextSelection
                            inputRef={register()}
                            name="office_day"
                            data={productOptions}
                            onChange={handleOffice}
                        />
                        {cOffice !== "custom" && (
                            <div className="hours-diff">
                                <TextField
                                    inputRef={register()}
                                    id="start"
                                    type="time"
                                    name="office_time_start"
                                    defaultValue="09:00"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <FormText>
                                    <p className="body1">to</p>
                                </FormText>
                                <TextField
                                    inputRef={register()}
                                    id="end"
                                    type="time"
                                    name="office_time_end"
                                    defaultValue="18:00"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        )}
                    </Section>
                    {cOffice === "custom" && (
                        <Section className="full office">
                            <FormText>
                                <p className="subtitle1"></p>
                            </FormText>
                            <div className="office_custom">
                                <Controller
                                    name="office_time_custom"
                                    render={(props) =>
                                        office_custom.map((o_c) => (
                                            <div className="office_day" key={o_c.value}>
                                                <FormText>
                                                    <p className="body1">{o_c.name}</p>
                                                </FormText>
                                                <div className="hours-diff">
                                                    <TextField
                                                        id="office_time_start"
                                                        type="time"
                                                        name="office_time_start"
                                                        onChange={(e) =>
                                                            props.onChange(handleCustomOffice(e, o_c.name))
                                                        }
                                                        defaultValue={
                                                            contacts?.office_time_custom?.find(
                                                                (item) => item.day_name === o_c.name,
                                                            )?.office_time_start
                                                        }
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                    <FormText>
                                                        <p className="body1">to</p>
                                                    </FormText>
                                                    <TextField
                                                        id="office_time_end"
                                                        type="time"
                                                        name="office_time_end"
                                                        onChange={(e) =>
                                                            props.onChange(handleCustomOffice(e, o_c.name))
                                                        }
                                                        defaultValue={
                                                            contacts?.office_time_custom?.find(
                                                                (item) => item.day_name === o_c.name,
                                                            )?.office_time_end
                                                        }
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    control={control}
                                />
                            </div>
                        </Section>
                    )}
                    <Controller
                        name="support_public_holiday"
                        render={(props) => (
                            <FormControlLabel
                                className="half"
                                control={
                                    <Checkbox
                                        color="primary"
                                        onChange={(e) => props.onChange(e.target.checked)}
                                        defaultChecked={contacts?.support_public_holiday}
                                    />
                                }
                                label="Also support on public holidays"
                            />
                        )}
                        control={control}
                    />
                    <FormButton ccolor="blue" cvariant="contained" type="submit" fullWidth>
                        Save changes
                    </FormButton>
                </FormCategory>
            </form>
        )
    );
};
