import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { setLogout } from "service/actions/authentication";
import { setSnackbar } from "service/actions/site";
import {
    admincompanydetail,
    admincompanylist,
    getadmincommission,
    storecategory,
    storecommission,
} from "service/apis/admin/application";

export const useGetApplicationList = (params) => {
    const dispatch = useDispatch();
    if (!params.size) {
        params.size = 30;
    }

    return useQuery(["application-list", params], () => admincompanylist(params), {
        onSuccess: (success) => {
            if (success?.message?.includes("jwt expired")) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                dispatch(setLogout());
                return;
            }
            return success;
        },
        select: (res) => res.data,
    });
};

export const useGetMerhcantApplication = (id) => {
    const dispatch = useDispatch();
    return useQuery(["merchant-application", id], () => admincompanydetail(id), {
        enabled: !!id,
        onSuccess: (success) => {
            if (success?.message?.includes("jwt expired")) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                dispatch(setLogout());
                return;
            }
            return success;
        },
        select: (res) => res.data,
    });
};

export const useGetCommission = (id) => {
    const dispatch = useDispatch();
    return useQuery(["merchant-comission", id], () => getadmincommission(id), {
        enabled: !!id,
        onSuccess: (success) => {
            if (success?.message?.includes("jwt expired")) {
                dispatch(setLogout());
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                return;
            }
            return success;
        },
        select: (res) => res.data,
    });
};

export const useAdminApplicationActions = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const { mutate: updateCommission, isLoading: onUpdateCommission } = useMutation(
        (payload) => storecommission(payload),
        {
            onSuccess: (res) => {
                if (res.data?.message?.includes("jwt expired")) {
                    dispatch(setLogout());
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: "Your session has expired. Please sign in again.",
                            severity: "error",
                        }),
                    );
                    return;
                }
                if (res.ok) {
                    dispatch(setSnackbar({ open: true, message: "Update Commissions Success", severity: "success" }));
                    return;
                }
            },
        },
    );

    const { mutate: updateCategory, isLoading: onUpdateCategory } = useMutation((payload) => storecategory(payload), {
        onSuccess: (res, payload) => {
            if (res.data?.message?.includes("jwt expired")) {
                dispatch(setLogout());
                dispatch(
                    setSnackbar({
                        open: true,
                        message: "Your session has expired. Please sign in again.",
                        severity: "error",
                    }),
                );
                return;
            }
            if (res.ok) {
                queryClient.invalidateQueries(["merchant-comission", payload.company_id]);
                dispatch(setSnackbar({ open: true, message: "Update Category Success", severity: "success" }));
                return;
            }
        },
    });

    return {
        updateCommission,
        onUpdateCommission,
        updateCategory,
        onUpdateCategory,
    };
};
