import { FormGroup, Paper, styled } from "@material-ui/core";
import { FormButton } from "components/Global";

export const RegisterContainer = styled(Paper)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
});

export const RegisterContent = styled("div")(({ theme }) => ({
    padding: "0px 50px 0px 30px",
}));

export const LoadingContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    zIndex: "500",
    fontSize: 50,
    gap: 20,
});

export const RegisterFoot = styled("div")(({ theme }) => ({
    borderTop: "1px solid #BDBDBD",
    margin: "15px 35px 0px",
    padding: "25px 25px 0px",
    "& .form-group": {
        width: "100%",
        "& .MuiFormControlLabel-root": {
            width: "100%",
        },
    },
}));

export const RegisterFormTitle = styled("div")({
    display: "flex",
    gap: "20px",
    alignItems: "center",
});

export const RegisterTitle = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    margin: "35px 0 20px",
    padding: "0 50px",
});

export const AgreementFormGroup = styled(FormGroup)({
    marginBottom: "60px",
    "& > .MuiFormControlLabel-root": {
        margin: "8px 0",
        cursor: "unset",
        "& > *:first-child": {
            cursor: "pointer",
        },
    },
});

export const FormWrapper = styled("div")({
    display: "flex",
});

export const SideMenu = styled("div")(({ theme }) => ({
    border: `1px solid ${theme.yoho.md_color.grey[400]}`,
    minWidth: "380px",
    marginRight: "30px",
    marginBottom: "25px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "20px",
    height: "fit-content",
    borderRadius: 16,
    position: "sticky",
    top: "35px",
    "& > *": {
        width: "100% !important",
    },
}));

export const SmallButton = styled(FormButton)(({ theme }) => ({
    height: "fit-content",
    width: "fit-content",
    textAlign: "center",
    padding: "9px 15px",
    lineHeight: "18px",
    ...theme.yoho.typography.subtitle2,
}));

export const CommissionContainer = styled("div")(({ theme }) => ({
    backgroundColor: theme.yoho.md_color.grey[100],
    padding: "30px",
    width: "600px !important",
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    gap: "20px",
    boxSizing: "border-box",
    "& > div": {
        "&.right": {
            justifySelf: "flex-end",
            "& > div": {
                width: "50px",
            },
        },
        "&.title": {
            ...theme.yoho.typography.subtitle1,
        },
    },
}));
