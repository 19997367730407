import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { styled, withStyles } from "@material-ui/core";
import { timelinedata } from "./timelinedata2";
import { FormText } from "components/Global/Form";
import Label from "./Label";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { convertOrderStatus } from "../../../helper";
import moment from "moment";

const Content = styled("div")({
    display: "flex",
    gap: 20,
    "& > div:last-of-type": {
        "& > div:first-of-type": {
            display: "flex",
            gap: 20,
        },
    },
});

const AdditionalMessage = styled("div")({
    display: "flex",
});

const $timelineData = createSelector(
    (state) => state,
    (_, logs) => logs,
    (_, logs, data) => data,
    (_, logs, data) => {
        const logsData = data?.RefundRequests?.reduce(
            (prev, curr) => [
                ...prev,
                {
                    order_status: 7,
                    select_name: "System",
                    pay_status: 2,
                    refund_status: curr.status,
                    log_time: curr.request_time,
                    action_note: `case ${curr.status === 1 ? "opened" : "closed"}`,
                    ...curr,
                },
            ],
            [],
        );
        return logsData;
    },
);

export default withStyles(() => ({
    timelineRoot: {
        padding: 0,
        marginTop: 0,
    },
    timelineItemRoot: {},
    timelineItemMissingOppositeContent: {
        "&:before": {
            flex: 0,
            padding: 0,
        },
    },
    timelineContentRoot: {
        display: "flex",
        padding: "10px 16px",
        width: "100%",
        gap: 30,
        justifyContent: "space-between",
        "& > div:last-child": {
            display: "flex",
            minWidth: "fit-content",
            justifyContent: "flex-end",
        },
        "&:not(:last-of-type)": {
            maxWidth: "fit-content",
        },
    },
    timelineDotRoot: {
        padding: 8,
    },
}))(({ classes, data, logs, ...props }) => {
    const timelineData = useSelector((state) => $timelineData(state, logs, data));
    return (
        <Timeline classes={{ root: classes.timelineRoot }} {...props}>
            {timelineData?.map((timeline, index) => (
                <TimelineItem
                    key={index}
                    classes={{
                        root: classes.timelineItemRoot,
                        missingOppositeContent: classes.timelineItemMissingOppositeContent,
                    }}
                >
                    <TimelineSeparator>
                        <TimelineDot classes={{ root: classes.timelineDotRoot }} />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent classes={{ root: classes.timelineContentRoot }}>
                        <Content>
                            <FormText color="grey" code={500}>
                                <p className="body2">{timeline.select_name}</p>
                            </FormText>
                            <div>
                                <div>
                                    <Label
                                        text={convertOrderStatus({
                                            order_status: timeline?.order_status,
                                            shipping_status: timeline?.shipping_status,
                                            pay_status: timeline?.pay_status,
                                            refund_status: timeline?.refund_status,
                                        })}
                                    />
                                    <FormText
                                        color={timeline?.data?.message?.color}
                                        code={timeline?.data?.message?.code}
                                    >
                                        <p className="body2">{timeline?.action_note}</p>
                                    </FormText>
                                </div>
                                {timeline?.data?.additional_message &&
                                    Object.entries(timeline?.data?.additional_message)?.map((msg, index) => (
                                        <AdditionalMessage key={index}>
                                            <FormText color="grey" code={500}>
                                                <p className="body2">{msg[0]}:&nbsp;</p>
                                            </FormText>
                                            <FormText color="grey" code={800}>
                                                <p className="body2">{msg[1]}</p>
                                            </FormText>
                                        </AdditionalMessage>
                                    ))}
                            </div>
                        </Content>
                        <FormText color="grey" code={500}>
                            <p className="body2">
                                {moment(timeline?.formatted_log_time).format("MMM DD, YYYY HH:mm:ss")}
                            </p>
                        </FormText>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
});
