import { Checkbox } from "@material-ui/core";
import { YHPagination, YohoButtonIcon } from "components/Global";
import { FormText } from "components/Global/Form";
import { UncontrolledNormalSelect } from "components/Global/Form/NormalSelect";
import Table from "components/Global/Table";
import Column from "components/Global/Table/Column";
import Pagination from "components/Global/Table/Pagination";
import { Topbar, Topleft, TopRight } from "components/Global/Table/Topbar";
import useExportToExcel from "helper/exportTable";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationParam } from "service/actions/admin";
import { showOptions } from "./constant";
import { ApplicationContainer, CustomButton } from "./StyledComponent";
import Action from "./table/Action";
import ApplicationDetails from "./table/ApplicationDetails";
import ContactEmail from "./table/ContactEmail";
import ContactNumber from "./table/ContactNumber";
import ContactPerson from "./table/ContactPerson";
import SubmissionDate from "./table/SubmissionDate";

export default ({ data, isLoading, currentTab }) => {
    const dispatch = useDispatch();

    const filter = useSelector((state) => state.admin.param);
    const setFilter = (newFilter) => dispatch(setApplicationParam(newFilter));

    const categorydata = useSelector((state) => state.site.master.category);
    const [checked, setChecked] = useState([]);
    const [exportParam, setExportParam] = useState(null);
    const { isExporting } = useExportToExcel(exportParam);

    const handleCheck = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) newChecked.push(value);
        else newChecked.splice(currentIndex, 1);
        setChecked(newChecked);
    };

    const CheckColumn = (rowData) => {
        return (
            <Checkbox
                color="primary"
                size="small"
                checked={checked.includes(rowData.id)}
                onChange={() => handleCheck(rowData.id)}
            />
        );
    };

    return (
        <ApplicationContainer>
            <Table data={data?.datas || []} loading={isLoading}>
                <Topbar>
                    <Topleft>
                        {!isLoading && (
                            <>
                                <Checkbox
                                    color="primary"
                                    size="small"
                                    checked={checked.length === data.datas.length && checked.length !== 0}
                                    onChange={(_, status) => setChecked(status ? data.datas.map((e) => e.id) : [])}
                                />
                                <div>{checked.length || 0} Selected</div>
                            </>
                        )}
                        {checked.length > 0 && (
                            <CustomButton
                                onClick={() => {
                                    const selectedApplication = data.datas.filter((e) => checked.includes(e.id));
                                    setExportParam({
                                        target: "admin-application",
                                        data: selectedApplication,
                                        category: categorydata,
                                    });
                                    setChecked([]);
                                }}
                            >
                                Export Selected
                            </CustomButton>
                        )}
                    </Topleft>
                    {/* <TopRight className="full">
                        {!isLoading && !isExporting && (
                            <YohoButtonIcon
                                src="/assets/icon/export.svg"
                                width={80}
                                height={35}
                                text="Export"
                                cursor="pointer"
                                onClick={() =>
                                    setExportParam({
                                        target: "admin-application",
                                        data: data.datas,
                                        category: categorydata,
                                    })
                                }
                            />
                        )}
                        {isExporting && <span>Exporting...</span>}
                    </TopRight> */}
                </Topbar>
                <Column type="custom" width={60} render={CheckColumn} />
                <Column field="submission_date" label="Submission Date" render={SubmissionDate} />
                <Column
                    field={["application_id", "company_name_chinese", "company_name_english"]}
                    label="Application Detail"
                    render={ApplicationDetails}
                    fixed
                />
                <Column
                    field={["first_name", "last_name", "personal_position"]}
                    render={ContactPerson}
                    label="Contact Person"
                />
                <Column
                    field={["contact_international_code_id", "contact_number"]}
                    label="Contact Number"
                    render={ContactNumber}
                />
                <Column field="personal_email" render={ContactEmail} label="Contact Email" />
                {currentTab === "approved" && (
                    <Column
                        type="custom"
                        label="Store Page"
                        width={130}
                        render={(rowData) => (
                            <a href={`${process.env.REACT_APP_B2C_DOMAIN}/zh-hk/store/${rowData.id}`} target="_blank">
                                Visit
                            </a>
                        )}
                    />
                )}
                <Column type="custom" label="Actions" render={(rowData) => <Action data={rowData} />} />
            </Table>
            <Pagination>
                <div>
                    <YHPagination
                        page={filter.page}
                        size={data?.total_pages}
                        action={(e) => setFilter({ ...filter, page: e })}
                    />
                </div>
                <div>
                    <UncontrolledNormalSelect
                        variant="standard"
                        data={showOptions}
                        name="size"
                        prefix="Show"
                        onChange={(e) => setFilter({ ...filter, ...e })}
                        value={filter.size}
                    />
                    <FormText>
                        <p className="subtitle2">Applications out of {data?.total_items[filter.status] || 0}</p>
                    </FormText>
                </div>
            </Pagination>
        </ApplicationContainer>
    );
};
