import { styled } from "@material-ui/core";

const Column = styled("div")({
    display: "flex",
    flexDirection: "column",
});

export default (entry) => {
    return (
        <Column>
            <span>
                Application# <strong>{entry.application_id}</strong>
            </span>
            <span>{entry.company_name_chinese}</span>
            <span>{entry.company_name_english}</span>
        </Column>
    );
};
