import { MenuItem, MenuList } from "@material-ui/core";
import Menu from "components/Global/Layout/Menu";
import { useSelector } from "react-redux";

export default ({ refs, ...props }) => {
    const { isAdmin, isImpersonate, permissions = [] } = useSelector((state) => state.profile);

    const canImpersonate = isAdmin && permissions.includes("mc_impersonate");

    return (
        <Menu refs={refs}>
            <MenuList>
                <MenuItem onClick={props.logoutAction}>Logout</MenuItem>
                {!isAdmin && <MenuItem onClick={props.profileAction}>Profile</MenuItem>}
                {canImpersonate && <MenuItem onClick={props.impersonateAction}>Impersonate</MenuItem>}
                {isImpersonate && <MenuItem onClick={props.stopImpersonateAction}>End Impersonation</MenuItem>}
            </MenuList>
        </Menu>
    );
};
