import produce from "immer";
import * as merchantActions from "service/actions/merchant";

const initialState = {
    isAdmin: null,
    isImpersonate: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case merchantActions.setProfile().type:
            for (const key in action.data) {
                draft[key] = action.data[key];
            }
            break;
        default:
    }
}, initialState);
